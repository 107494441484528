@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro&family=Ubuntu&display=swap");

*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

html,
body {
  padding: 0;
  margin: 0;
  overflow-x: clip;
  scroll-behavior: smooth;
}

html {
  color-scheme: dark;
}
body {
  color: white;
  background: black;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a {
  margin: 0;
  padding: 0;
}

html {
  font-size: 10px;
}

a {
  color: inherit;
  text-decoration: none;
}

a.skip-link {
  padding: 10px 20px;
  position: fixed;
  z-index: 1000;
  top: 0px;
  transform: translateY(-100%);
  transition: transform 0.25s;
}

a.skip-link:focus {
  transform: translateY(0);
  transition: transform 0.25s;
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
  body {
    color: white;
    background: black;
  }
}

@media (prefers-reduced-motion: reduce) {
  *,
  *::before,
  *::after {
    animation-duration: 0.001s !important;
    transition-duration: 0.001s !important;
    scroll-behavior: auto !important;
  }
}

ul,
ol {
  margin: unset;
  padding: unset;
}

body {
  font-family: "Source Sans Pro", sans-serif;
}

.fadeIn {
  animation: 0.5s fadeIn forwards;
}

.fadeOut {
  animation: 0.5s fadeOut forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translate(-20px, 0);
  }
  to {
    opacity: 1;
    transform: translate(0px, 0px);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translate(0px, 0px);
  }
  to {
    transform: translate(-20px, 0);
    opacity: 0;
  }
}
